import React, { useEffect, useCallback, useState, useRef } from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { Link } from 'gatsby'
import cx from 'classnames'

import { Image } from 'src/components/image'
import { Serializer } from 'src/utils/serializer'

import { Tab } from 'src/components/svgs'
import { RotatingText } from 'src/utils/rotatingText'

import { motion } from 'framer-motion'

export interface ShoppableHeroProps {
  data: {
    shortDescription?: string
    productImages: Array<{
      image: {
        imageId: string
      }
    }>
    slides: Array<{
      image: {
        imageId: string
      }
    }>
  }
}

const imageVariants = {
  open: {
    opacity: 1
  },
  closed: {
    opacity: 0
  }
}

const textVariants = {
  open: {
    opacity: 1,
    transform: `translateY(0px)`,
    transition: {
      stiffness: 400,
      damping: 40,
      type: 'spring'
    }
  },
  closed: {
    opacity: 0,
    transform: `translateY(30px)`,
    transition: {
      stiffness: 400,
      damping: 40,
      type: 'spring'
    }
  }
}

export const ShoppableHero = ({ data }: ShoppableHeroProps) => {
  const [active, setSlide] = useState(0)
  const {
    slides,
    shortDescription,
    productImages,
    badgeText
  } = data

  const setSlideValue = () => {

    if (active === slides.length - 1) {
      setSlide(0)
    } else {
      setSlide(active + 1)
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSlideValue()
    }, 4000)

    return () => {
      clearInterval(intervalId)
    }
  }, [active])

  return (
    <div className='x hero'>
      <div className='row gutter--none df fw fdrr hero__row'>
        <div className='c14 c7--1000 col'>
          <div className='df jcc aic y pt3--800 pt1--1200'>
            <div className='p6--1000 mb8 mb0--1000 x'>
              <div className='bl--1000 br--1000 bc bt--1000 hero__window mxa'>
                <div className='tc pt4--1000 pl4--800 pr4--800  pb2--800'>
                  <div className='pr'>
                    <Image className='hero__window-image' imageId={productImages[0].image.imageId} />
                    {badgeText && (
                      <div className={cx('pa right z1 hero__badge bottom', `color--blue`)}>
                        <Tab />
                        <div className='pa top right badge__text founders color--white caps'>
                          <RotatingText text={badgeText} />
                        </div>
                      </div>
                    )}
                  </div>
                  <p className='s32 s36--1000 container--400 mxa pt1--600 pb3 serif pb0--1000 mt2 mt1--800'>{shortDescription}</p>
                </div>
                <div className='row gutter--none bt bc bb'>
                  <div className='col c2 c0--1000' />
                  <div className='col c10 c14--1000'>
                    <Link className='db button x bc bn' to='/collections/all'>
                      <span className='medium'>Get Cooking</span>
                    </Link>
                  </div>
                  <div className='col c2 c0--1000' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='c14 c7--1000 col hero__slide-wrap pr'>
          {slides.map((slide, i) => (
            <motion.div
            initial={false}
            animate={active === i ? 'open' : 'closed'}
            key={slide._key}
            className='pa x y top left z1'>
              <motion.div variants={textVariants} className='pa p3 x y p5--800 jcc aic df tc color--white hero__slide-text z2'>
                <h2 className='h1 db '>
                  <span className='italic'>{slide.italicTitle}</span><br />
                <span className='sans'>{slide.sansTitle}</span></h2>
              </motion.div>
              <motion.div variants={imageVariants}>
                <Image className='block hero__slide z1 pa x y' imageId={slide.image.imageId} />
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ShoppableHero